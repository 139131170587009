import Theme from "gatsby-theme-facet-default/src/interfaces/themes"
import { createResponsiveStyles } from "gatsby-theme-facet-default/src/helpers/theme"

const theme: Theme = {
  colors: {
    background: "#FFFFFF",
    text: "#262626",
    primary: "#366B92",
    primaryHover: "#E2ECF3",
    primaryDisabled: "#366B92",
    secondary: "#D9A867",
    secondaryHover: "#DBB684",
    secondaryDisabled: "#F7EEE1",
    buttonText: "#2D2D2D",
    duckEggBlue: "#E2ECF3", // The variable name is corresponding to the default/primary theme
  },
  buttons: {
    primary: {
      bg: "secondary",
      color: "buttonText",
      borderRadius: "4px",
      borderColor: "secondary",
      ":not(.Disabled):hover, :active": {
        color: "text",
        bg: "secondaryHover",
        textDecoration: "none",
        borderColor: "secondaryHover",
      },
      "&.Disabled": {
        cursor: "default",
        color: "secondary",
        bg: "secondaryDisabled",
        borderColor: "secondaryDisabled",
      },
    },
    secondary: {
      borderColor: "secondary",
      ":not(.Disabled):hover, :active": {
        bg: "secondaryDisabled",
      },
      "&.Disabled": {
        bg: "secondaryDisabled",
        fontWeight: 600,
        cursor: "default",
        color: "secondary",
        borderColor: "secondary",
      },
    },
    roundedPrimary: {
      bg: "white",
      color: "buttonText",
      borderColor: "secondary",
      ":not(.Disabled):hover, :active": {
        color: "buttonText",
        bg: "secondaryDisabled",
        borderColor: "secondary",
      },
    },
    navigation: {
      ":active, :hover, &.active": {
        borderBottomColor: "secondary",
      },
    },
    languageSwitcher: {
      color: "secondary",
      bg: "white",
      borderColor: "secondary",
      ":not(.Disabled):hover, :active": {
        color: "white",
        bg: "secondary",
        borderColor: "secondary",
      },
    },
    download: {
      width: "216px",
      variant: "buttons.secondary",
      color: "primary",
      fontWeight: 600,
      ":not(.Disabled):hover, :active": {
        color: "primary",
        bg: "secondaryDisabled",
      },
      ".Disabled": {
        color: "secondary",
        bg: "secondaryDisabled",
      },
    },
  },
  layout: {
    header: {
      py: createResponsiveStyles(2, 3, 3),
      px: createResponsiveStyles(3, 7),
    },
    logo: {
      width: "100%",
      maxWidth: createResponsiveStyles("132px", "235px"),
    },
    mobileLogo: {
      maxWidth: "271px",
    },
    formHeader: {
      py: createResponsiveStyles(4, 5, 5),
      px: createResponsiveStyles(4, 6, 8, 5),
    },
  },
}

export default theme
