// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-facet-default-src-pages-404-tsx": () => import("./../../../node_modules/gatsby-theme-facet-default/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-facet-default-src-pages-404-tsx" */),
  "component---node-modules-gatsby-theme-facet-default-src-pages-contact-tsx": () => import("./../../../node_modules/gatsby-theme-facet-default/src/pages/contact.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-facet-default-src-pages-contact-tsx" */),
  "component---node-modules-gatsby-theme-facet-default-src-pages-forms-tsx": () => import("./../../../node_modules/gatsby-theme-facet-default/src/pages/forms.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-facet-default-src-pages-forms-tsx" */),
  "component---node-modules-gatsby-theme-facet-default-src-pages-index-tsx": () => import("./../../../node_modules/gatsby-theme-facet-default/src/pages/index.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-facet-default-src-pages-index-tsx" */),
  "component---node-modules-gatsby-theme-facet-default-src-pages-physicians-tsx": () => import("./../../../node_modules/gatsby-theme-facet-default/src/pages/physicians.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-facet-default-src-pages-physicians-tsx" */),
  "component---node-modules-gatsby-theme-facet-default-src-pages-privacy-tsx": () => import("./../../../node_modules/gatsby-theme-facet-default/src/pages/privacy.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-facet-default-src-pages-privacy-tsx" */)
}

